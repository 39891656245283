import React, { Component } from "react";
import AdminProvider from "./AdminProvider";
import { withAuthenticator } from "aws-amplify-react";
import config from "../../aws-exports";
const Amplify = require("aws-amplify");
Amplify.default.configure(config);

class Container extends Component {
  signOut = () => {
    Amplify.default.Auth.signOut().catch(err => console.log(err));
  };

  render() {
    return (
      <div>
        <AdminProvider signOut={this.signOut} />
      </div>
    );
  }
}

const MyTheme = {
  container: { fontFamily: "Poppins !imnportant" },
  toast: { marginTop: 80, backgroundColor: "#0094e9" },
  a: { color: "#0094e9" },
  button: { backgroundColor: "#0094e9", borderColor: "#0094e9" },
  signInButtonIcon: { display: "none" }
};

export default withAuthenticator(Container, false, [], null, MyTheme, {
  signUpConfig: {
    signUpFields: [
      { label: "Name", key: "name", required: true, type: "string" }
    ]
  }
});
