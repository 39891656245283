import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({});

class Settings extends PureComponent {
  render() {
    const { classes } = this.props;
    return <div>{"Settings"}</div>;
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Settings);
