import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({});

class Docs extends PureComponent {
  render() {
    const { classes } = this.props;
    return <div>{"API Docs"}</div>;
  }
}

Docs.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Docs);
