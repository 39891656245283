// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-2',
  aws_cognito_identity_pool_id:
    'us-east-2:cce6a108-740a-4c8a-8185-b5514d41548e',
  aws_cognito_region: 'us-east-2',
  aws_user_pools_id: 'us-east-2_OHokuOzXH',
  aws_user_pools_web_client_id: '5g4jo4j8esss5n1avb35peefg1',
  oauth: {},
};

export default awsmobile;
