import React, { Component } from "react";
import Navbar from "../get-ein/components/layout/navbar";
import Container from "./Container";

class main extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <Container />
      </div>
    );
  }
}

export default main;
